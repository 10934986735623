<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import { required} from "vuelidate/lib/validators";

import {
	projectMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Projects",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "",
      items: [],
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      currentPage:1,
			perPage:9,
      rows:0,
      projects:[],
      project:{},
      submitted: false,
      type :null
     }
  },
  validations: {
		project: {
			name: {
				required,
			},
		}
	},
  mounted() {
      this.loadProjects();
  },
  created(){
		this.debounceProjects = _debounce(this.searchProjects.bind(this), 1000);
	},
  methods: {
    ...projectMethods,

    loadItemsCountByProject(){
    
      for (let i=0;i< this.projects.length;i++){
        let p = this.projects[i];
        
        const params={
					q: `where[project]=${p._id}`,
				}
        this.getItems(params).then((res)=>{
          p.totalItems = res.totalCount;
          this.$set(this.projects, i, p)
        });
        
      }
    },

    onCancelCreateClicked(){
      this.showModal = false;
      this.submitted = false;
      this.project ={};
		},
    loadProjects(){
      let loader = this.$loading.show();
      this.isLoading = true;
      
      const params={
        q: `limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
      }
      this.getProjects(params).then((projects)=>{
        if(projects&& projects.data){
          this.projects= projects.data;
          this.rows= projects.totalCount
        this.loadItemsCountByProject();
      }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('projects.get_projects_error'),title: this.$t('projects.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    
    onProjectListPageClicked() {
				this.loadProjects();
		},
    
    onNewProjectClicked(){
      this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

      this.project.type = this.type;

			let loader = this.$loading.show();
			this.createProject(this.project).then((res)=>{
				
				this.$notify({ type: 'success', text: this.$t('projects.create_project_success'),title: this.$t('projects.title') });
				this.showModal= false;

         this.$router.push(
            {
              path: `/new-project?id=${res.data._id}`,
            }
          ).catch(() => {});
				
			}).catch((()=>{
        this.$notify({ type: 'error', text: this.$t('projects.create_project_error'),title: this.$t('projects.title') });
			})).finally(()=>{
				this.isLoading = false;
				loader.hide();
				this.submitted = false;
			});
      
    },

    onProjectClicked(project){
       this.$router.push(
            {
              path: `/new-project?id=${project._id}`,
            }
          ).catch(() => {});
    },

    searchProjects(query){
      
      this.isLoading = true;
      let loader = this.$loading.show();

      if(query){
        this.campaigns=[];
        this.currentPage = 1;
				const params={
					q: `where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}
        this.getProjects(params).then((projects)=>{
          if(projects&& projects.data){
            this.projects= projects.data;
            this.rows= projects.totalCount

            this.loadItemsCountByProject();
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.currentPage = 1;
        loader.hide();
        this.loadProjects();
      }
    },
    
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('projects.title')" :items="items" />
      <div class="row">
          <div class="card">  
              <div class="card-body">
                  <div class="row mb-2">
                      <div class="col-sm-4">
                          <div class="search-box me-2 mb-2 d-inline-block">
                          <div class="position-relative">
                              <input
                              type="text"
                              class="form-control"
                              :placeholder="$t('common.search')"
                              @input="debounceProjects($event.target.value)"
                              />
                              <i class="bx bx-search-alt search-icon"></i>
                          </div>
                          </div>
                      </div>
                      <div class="col-sm-8">
                          <div class="text-sm-end">
                            <button
                              type="button"
                              class="btn btn-success btn-rounded mb-2 me-2"
                              @click="showModal=true">
                                <i class="mdi mdi-plus me-1"></i> {{this.$t('projects.new_project')}}
                            </button>
                            <b-modal
                              v-model="showModal"
                              :title="$t('projects.new_project')"
                              title-class="text-black font-18"
                              body-class="p-3"
                              hide-footer>
                                <form @submit.prevent="onNewProjectClicked">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="name">{{$t('projects.new_project_name')}}*</label>
                                        <input
                                          id="name"
                                          v-model="project.name"
                                          type="text"
                                          class="form-control"
                                          :placeholder="$t('projects.new_project_name_placeholder')"
                                          :class="{'is-invalid':submitted && $v.project.name.$error}"
                                        />
                                          <div v-if="submitted && !$v.project.name.required" class="invalid-feedback">
                                            {{$t('projects.new_project_name_required')}}
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-end pt-5 mt-3">
                                      <b-button variant="light" @click="onCancelCreateClicked">{{$t('common.cancel')}}</b-button>
                                      <b-button type="submit" variant="primary" class="ms-1"
                                      >{{$t('common.confirm')}}</b-button>
                                  </div>
                                </form>
                            </b-modal>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div v-for="project in projects" :key="project.id" class="col-xl-4 col-sm-6 " v-on:click="onProjectClicked(project)">
          <div class="card interact">
          <div class="card-body">
              <div class="d-flex">
                <div class="avatar-md me-4 font-size-18 ">
                  <span class="avatar-title rounded-circle header-profile-user profile-picture">{{ project.name ? project.name.charAt(0) : ""}}</span>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                    <h5 class="text-truncate font-size-15">
                    <a href="javascript: void(0);" class="text-dark">{{
                        project.name
                    }}</a>
                    </h5>
                  </div>
                </div>
            </div>
          <div class="px-4 py-3 border-top">
              <ul class="list-inline mb-0">
              <li class="list-inline-item mb-0 me-3">
                    <span class="badge font-size-10"
                    :class="{
                      'bg-success': `${project.status}` === 'published',
                      'bg-warning': `${project.status}` === 'draft',
                  }"
                    >
                      {{project.status}}
                    </span>
                  </li>

              <li
                  v-b-tooltip.hover.top
                  class="list-inline-item me-3"
                  title="Registros">

                  <i class="bx bxs-data me-1"></i>
                  {{project.totalItems }} Items
              </li>
            </ul>
          </div>
        </div>
    </div>
    </div>
    <div class="text-center empty-list col-xl-12 mt-4" v-if="!isLoading && projects.length == 0">
        <span> {{ $t("projects.empty_list") }} </span>
        <div> <i class="bx bx-box me-1 text-muted display-4"/> </div>
    </div>
    <div class="row mb-4" v-if="rows > perPage">
      <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                  <b-pagination
                      @input="onProjectListPageClicked"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                  ></b-pagination>
              </ul>
          </div>
      </div>
    </div>
    
  </Layout>
</template>

<style scoped>
  .profile-picture {
      height: 70px !important;
      width: 70px !important;
      padding: 0px !important;
  }

  .campaign-description {
    height: 70px !important;
  }

  .interact{
    cursor: pointer;
  }
</style>